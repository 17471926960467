.arrows-container {
width: 60px;
    height: 72px;
    position: relative;
    left: 0%;
    top: 20%; /* Adjusted for responsiveness */
    /* margin-left: -30px; */
    bottom: 0;
    padding-top: 2vw; /* Adjusted for responsiveness */
  }

  .arrows path {
    stroke: #636A96;
    fill: transparent;
    stroke-width: 8px; /* Adjusted stroke width for responsiveness */
    animation: arrow 1.5s infinite;
    -webkit-animation: arrow 1.5s infinite; /* Reduced animation time for responsiveness */
  }


  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
        .arrows-container {
         display: none;
        }

    .arrows path {
      stroke-width: 1.5vw;

    }
  }


  @keyframes arrow {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes arrow /*Safari and Chrome*/ {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .arrows path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s; /* Safari 和 Chrome */
  }

  .arrows path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
  }

  .arrows path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;



  }
  html {
    font-size: calc (10px + 1vmin);

  }


  /* NavBar.css */

.navbar-container {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2rem;

  }

  .navbar-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .nav-link {
    text-decoration: none;
    color: black;
    font-size: 1rem;
  }

  /* Add more styles for responsiveness and other screen sizes if needed */

  .nav-link {
    text-decoration: none;
    color: black;
    position: relative;
    font-weight: 500;
  }

  .nav-link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px; /* Adjust this value to control the position of the line */
    width: 0;
    height: 3px; /* Adjust this value to control the thickness of the line */
    background-color: #00a0d5;
    transition: width 0.5s ease; /* Transition width property */
  }

  .nav-link:hover::after{
    width: 100%; /* Expand the line on hover and active states */
  }



  .nav-link:hover {
    color: #00a0d5;
  }

  .active {
    color: #00a0d5 !important; /* Overrides default active link color */
  }
  .custom-navlink.active {
    color: white !important; /* Ensure the color is always white for the custom NavLink */
  }
/* ----------------Limit the word on blog page description------- */
@media (max-width: 600px) {
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }


}
.HomeDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}








